import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='text-black m-3 w-100 container'>
            <div className="col-12">
                <h1 className='text-center fw-bold fs-2'>Privacy Policy</h1>
                <div>
                    <p>Ohoindia is committed to protecting the privacy of its users.
                        This Privacy Policy describes how Ohoindia collects, uses,
                        and shares personal information collected from users of the application.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Information We Collect</h4>
                    <h3 className="heading fs-5">Personal Information</h3>
                    <p>We may collect personal information such as name. email address,
                        and other contact details when you voluntarily provide them to us.
                    </p>
                </div>
                <div >
                    <h3 className="heading fs-5 mt-2">Usage Information</h3>
                    <p>We automatically collect certain information about your device and usage of the application,
                        including but not limited to your IP address, device type, operating system,
                        and browsing behaviour within the apllication.
                    </p>
                </div>
                <div >
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>How we Use Your Information</h4>
                    <p>We may use the information we collect for various purposes, including but not limited to:
                        <div className="list" style={{marginLeft: '20px'}}>
                            <li>Providing and maintaining the application</li>
                            <li>Improving and customizing the user experience</li>
                            <li>Sending you updated, and other communications related to the application</li>
                            <li>Detecting and preventing fraud or miuse of the application</li>
                        </div>
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Sharing Your Information</h4>
                    <p>We may share your information with third-party service providers
                        who assist us in operating our application, conducting our business,
                        or servicing you. We may also share your information when required by
                        law or to protect our rights or the rights to others.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Children's Privacy</h4>
                    <div className="list" style={{marginLeft: '20px'}}>
                        <li>We do not knowingly collect personal information from children under
                            the age of 18 without verifiable parental consent.</li>
                        <li>If you are a parent or guardian and believe that yout child has
                            provided personal information to us without your consent,
                            please contact us immediately so that we can take appropriate action.</li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Your Choices</h4>
                    <p>You may choose not to provide certain personal information,
                        but this may limit your ability to use certain features of the application.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Delete Your Personal Data</h4>
                    <div className="list" style={{marginLeft: '20px'}}>
                        <li>You have the right to delete or request that We assist in deleting the
                            Personal Data that We have collected about You. </li>
                        <li>Our Service may give You the ability to delete certain information about
                            You from within the Service. </li>
                        <li>You may update, amend, or delete Your information at any time by signing
                            in to Your Account, if you have one, and visiting the account settings
                            section that allows you to manage Your personal information.
                            You may also contact Us to request access to, correct, or delete
                            any personal information that You have provided to Us. </li>
                        <li>Please note, however, that We may need to retain certain information
                            when we have a legal obligation or lawful basis to do so. </li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Security</h4>
                    <p>We take reasonable measures to protect the information we collect from loss,
                        misuse, and unauthorized access, disclosure, alteration and destruction.
                    </p>
                </div>
                <div >
                    <h3 className="fw-bolder fs-2 mt-5">Refund, Retrun and Cancellation Policy</h3>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Refund Policy</h4>
                    <div className="list" style={{marginLeft: '20px'}}>
                        <li>We strive to ensure utmost satisfaction with every purchase made through our app.
                            If you find yourself dissatisfied with a product or service,
                            we offer a refund under certain circumstances.</li>
                        <li>Refunds will be processed within 15-16 days after cancelling the services</li>
                        <li>Refunds may be granted for reasons including service dissatisfaction,
                            or accidental purchases.</li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Cancellation Policy</h4>
                    <div className="list" style={{marginLeft: '20px'}}>
                        <li>We understand that circumstances may change, and you may need to cancel
                            a subscription, membership, or purchase. Here's how our cancellation process works:</li>
                        <li>Cancellations can be made the before the product is shipped.
                            To cancel, please reach out to us at
                            <a href="https://www.contact@ohoindialife.com" style={{cursor: 'pointer'}}>
                                contact@ohoindialife.com
                            </a></li>
                        <li>We're here to assist you throughout the cancellation process.
                            If you encounter any difficulties or have questions,
                            please don't hesitate to reach out to our support team.</li>
                    </div>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Changes to this Privacy Policy</h4>
                    <p>We may update our Privacy Policy from time to time.
                        Any changes will be posted on this page with an updated revision date.
                    </p>
                </div>
                <div>
                    <h4 className='text-start fs-4 fw-bold ms-md-5 ms-2 mt-2'>Contact Us</h4>
                    <p>If you have any questions or concerns about our Privacy Policy,
                        please contact us at
                        <a href="https://www.contact@ohoindialife.com" style={{cursor: 'pointer'}}>
                            contact@ohoindialife.com
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default PrivacyPolicy;