import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import PrivacyPolicy from './Home/PrivacyPolicy';
import TermsandConditions from './Home/TermsAndConditions';
import RefundPolicy from './Home/RefundPolicy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
