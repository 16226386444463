import React from 'react';

const TermsandConditions = () => {
    return (
        <div className='text-black m-3 w-100 container'>
            <div className="col-12">
                <h1 className='text-center fw-bold fs-2'>Terms of Service</h1>
                <div className="sub-container">
                    <h3 className="heading fs-5">Introduction</h3>
                    <p>Welcome. These Terms and Conditions ("Terms") govern your
                        use of our payment gateway services. By using our services,
                        you agree to be bound by these Terms.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Acceptance of Terms</h3>
                    <p>To use our services, you must agree to these Terms.
                        By registering for an account or making a transaction through our gateway,
                        you accept and agree to these Terms.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Eligibility</h3>
                    <p>Our services are available to individuals who are at least 18 years old
                        and have the legal capacity to enter into binding contracts.
                        We reserve the right to refuse service to anyone at our discretion.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Account Registration</h3>
                    <p>You must create an account to use our services. You are responsible
                        for providing accurate and complete information during registration
                        and for maintaining the confidentiality of your account information.
                        You agree to notify us immediately of any unauthorized use of your account.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">User Obligations</h3>
                    <p>You agree to use our payment gateway only for lawful purposes.
                        Prohibited activities include, but are not limited to,
                        engaging in fraudulent transactions, money laundering,
                        and any activities that violate applicable laws and regulations.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Payment Processing</h3>
                    <p>Our payment gateway supports various types of transactions.
                        Transaction limits and processing times may vary depending on the
                        type of transaction and the payment method used.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Refunds and Chargebacks</h3>
                    <p>Our policy on refunds and chargebacks is designed to protect both merchants and consumers.
                        If you need to request a refund or dispute a transaction, please follow the procedures
                        outlined in our Refund and Return Policy which is mentioned in out privacy policy.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Security</h3>
                    <p>We implement robust security measures to
                        protect your transactions and personal information.
                        However, you are also responsible for safeguarding your
                        account information and any sensitive data related to your transactions.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Privacy</h3>
                    <p>We are committed to protecting your privacy. Our
                        <a href="https://sites.google.com/view/ohoindia/home" target="_blank">Privacy policy</a>
                        explains how we collect, use, and protect your personal information.
                        By using our services, you agree to the terms of our Privacy Policy.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Security</h3>
                    <p>We implement robust security measures to
                        protect your transactions and personal information.
                        However, you are also responsible for safeguarding your
                        account information and any sensitive data related to your transactions.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Limitation of Liability</h3>
                    <p>Our services are provided "as is" without any warranties,
                        express or implied. We are not liable for any indirect, incidental,
                        or consequential damages arising from your use of our services.
                        Our total liability to you for any claim arising from your use of
                        our services is limited to the amount of fees you have paid to us.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Amendments to Terms</h3>
                    <p>We may update these Terms from time to time. We will notify you of any
                        material changes by posting the new Terms on our website and, if applicable,
                        sending you an email notification. Your continued use of our services after
                        such changes constitutes your acceptance of the new Terms.
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Contact Information</h3>
                    <p>If you have any questions or need support, please contact us at
                        <a href="https://www.contact@ohoindialife.com" style={{cursor: 'pointer'}}>
                            contact@ohoindialife.com
                        </a>
                    </p>
                </div>
                <div className="sub-container">
                    <h3 className="heading fs-5">Miscellaneous</h3>
                    <p>These Terms constitute the entire agreement between you and us
                        regarding your use of our services. If any provision of these Terms
                        is found to be unenforceable, the remaining provisions will continue
                        to be in effect. Our failure to enforce any right or provision of these
                        Terms will not be considered a waiver of those rights.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default TermsandConditions;